.forgot-password-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;

    .forgot-form-container {
        background-color: white;
        padding: 30px;
        font-size: 14px;
        width: 300px;

        text-align: center;
    }

    .goback {
        text-align: right;
        a {
            text-decoration: none;
        }
    }
}
