.confirmation {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;

    .otp-cognito {
        width: 40px !important;
        height: 40px;
        margin: 10px;
        font-size: 18px;
    }

    .otp-container > div {
        justify-content: center;
    }

    .confirmation-container {
        background-color: white;
        padding: 30px;
        font-size: 14px;

        text-align: center;

        img {
            width: 190px;
        }

        .email {
            font-weight: bold;
            color: black;
        }

        .confirm-code {
            color: #8b8787;
            margin-bottom: 30px;
        }

        .resend-message {
            font-size: 12px;

            .resend-btn {
                color: #00b3fe;
                cursor: pointer;
            }

            .error {
                color: #f5222d;
            }

            .success {
                color: #73d13d;
            }
        }

        .validation-message {
            color: #f5222d;
        }
    }

    .imx8-instruct {
        margin-top: 20px;
    }
    .success {
        color: #52c41a;
        font-weight: bold;
        font-size: 18px;
    }
    .error {
        color: #f5222d;
        font-weight: bold;
        font-size: 18px;
    }
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .verificationCode {
        font-size: 18px;
        font-weight: bold;
    }
    .manual {
        font-size: 12px;
        margin-top: 20px;
    }
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #7ac142;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .checkmark {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    }
    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px #7ac142;
        }
    }

    @media (max-width: 500px) {
        .confirmation-container {
            width: 100vw;
        }

        .otp-cognito {
            width: 30px !important;
            height: 30px;
            margin: 5px;
            font-size: 14px;
        }
    }
}
