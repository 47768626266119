.forgot-password {
    color: #00b3fe;
    cursor: pointer; 
}

.react-tel-input{
    .form-control {
        width: 100%;
    }
}

.ant-form-item-has-error {
    .input-phone-error {
        border-color: #ff4d4f;
    }
}