@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    background-color: #efefef;
}

.cognito-container {

    .form-container {
        width: 768px;
        padding: 0 10px;
        min-height: 100vh;
        background-color: white;
    }

    .flex-container {
        display: flex;
        justify-content: center;
    }
}

.logo-container {
    text-align: center;
    margin-bottom: 1rem;

    img {
        width: 300px;
        margin-bottom: 10px;
        padding-top: 40px;
    }
}
